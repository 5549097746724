import React from "react"
import Layout from "../components/Layout"
function about() {
  return (
    <Layout>
      <h1>About Content</h1>
    </Layout>
  )
}

export default about
